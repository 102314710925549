"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupType = exports.BulkOrderItemDimension = void 0;
var BulkOrderItemDimension;
(function (BulkOrderItemDimension) {
    BulkOrderItemDimension["Small"] = "small";
    BulkOrderItemDimension["Medium"] = "medium";
    BulkOrderItemDimension["Large"] = "large";
    BulkOrderItemDimension["XLarge"] = "extra-large";
})(BulkOrderItemDimension || (exports.BulkOrderItemDimension = BulkOrderItemDimension = {}));
var GroupType;
(function (GroupType) {
    GroupType["Folder"] = "folder";
    GroupType["BulkOrder"] = "bulkorder";
})(GroupType || (exports.GroupType = GroupType = {}));
