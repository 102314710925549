"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.supportedFontExtensions = exports.supportedVideoExtensions = exports.supportedAudioExtensions = exports.supportedImageExtensions = exports.FileMimeTypes = exports.FontMimeTypes = exports.FontFormat = exports.FontType = void 0;
var FontType;
(function (FontType) {
    FontType["Google"] = "google";
    FontType["Custom"] = "custom";
})(FontType || (exports.FontType = FontType = {}));
var FontFormat;
(function (FontFormat) {
    FontFormat["ttf"] = "truetype";
    FontFormat["woff2"] = "woff2";
    FontFormat["woff"] = "woff";
    FontFormat["otf"] = "opentype";
})(FontFormat || (exports.FontFormat = FontFormat = {}));
var FontMimeTypes;
(function (FontMimeTypes) {
    FontMimeTypes["ttf"] = "font/truetype";
    FontMimeTypes["woff"] = "application/x-font-woff";
    FontMimeTypes["woff2"] = "font/woff2";
    FontMimeTypes["otf"] = "font/opentype";
})(FontMimeTypes || (exports.FontMimeTypes = FontMimeTypes = {}));
var FileMimeTypes;
(function (FileMimeTypes) {
    FileMimeTypes["svg"] = "image/svg+xml";
})(FileMimeTypes || (exports.FileMimeTypes = FileMimeTypes = {}));
const readOnlySupportedImageExtensions = ['png', 'jpg', 'jpeg', 'ai', 'eps', 'pdf', 'heic'];
exports.supportedImageExtensions = readOnlySupportedImageExtensions;
const readOnlySupportedAudioExtensions = ['mp3', 'wav', 'aac', 'flac', 'ogg'];
exports.supportedAudioExtensions = readOnlySupportedAudioExtensions;
const readOnlySupportedVideoExtensions = ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'webm'];
exports.supportedVideoExtensions = readOnlySupportedVideoExtensions;
const readOnlySupportedFontExtensions = ['woff2', 'woff', 'ttf', 'otf'];
exports.supportedFontExtensions = readOnlySupportedFontExtensions;
