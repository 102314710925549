"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemeBuilderSettingType = exports.DefaultTheme = exports.ThemeVersionOrigin = exports.ThemeName = void 0;
var ThemeName;
(function (ThemeName) {
    ThemeName["Barebones"] = "barebones";
    ThemeName["Booklike"] = "booklike";
})(ThemeName || (exports.ThemeName = ThemeName = {}));
var ThemeVersionOrigin;
(function (ThemeVersionOrigin) {
    ThemeVersionOrigin["ThemeCreated"] = "themeCreated";
})(ThemeVersionOrigin || (exports.ThemeVersionOrigin = ThemeVersionOrigin = {}));
exports.DefaultTheme = ThemeName.Booklike;
var ThemeBuilderSettingType;
(function (ThemeBuilderSettingType) {
    ThemeBuilderSettingType["Color"] = "color";
    ThemeBuilderSettingType["ColorWithAlpha"] = "colorWithAlpha";
    ThemeBuilderSettingType["FontFamily"] = "font";
    ThemeBuilderSettingType["FontSize"] = "size";
    ThemeBuilderSettingType["Radio"] = "radio";
    ThemeBuilderSettingType["Select"] = "select";
    ThemeBuilderSettingType["Switch"] = "switch";
})(ThemeBuilderSettingType || (exports.ThemeBuilderSettingType = ThemeBuilderSettingType = {}));
