"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ECommerce = void 0;
var ECommerce;
(function (ECommerce) {
    ECommerce["Shopify"] = "shopify";
    ECommerce["Woocommerce"] = "woocommerce";
    ECommerce["Prestashop"] = "prestashop";
    ECommerce["Wix"] = "wix";
    ECommerce["CustomStore"] = "custom-store";
})(ECommerce || (exports.ECommerce = ECommerce = {}));
