"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VersionOrigin = void 0;
var VersionOrigin;
(function (VersionOrigin) {
    VersionOrigin["DraftCreated"] = "draftCreated";
    VersionOrigin["DraftDiscarded"] = "draftDiscarded";
    VersionOrigin["DraftPublished"] = "draftPublished";
    VersionOrigin["VersionRestored"] = "versionRestored";
})(VersionOrigin || (exports.VersionOrigin = VersionOrigin = {}));
