"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AllowedTransform = exports.PartType = void 0;
var PartType;
(function (PartType) {
    PartType["Image"] = "image";
    PartType["Logo"] = "logo";
    PartType["Text"] = "text";
})(PartType || (exports.PartType = PartType = {}));
var AllowedTransform;
(function (AllowedTransform) {
    AllowedTransform["Move"] = "move";
    AllowedTransform["Resize"] = "resize";
    AllowedTransform["Rotate"] = "rotate";
})(AllowedTransform || (exports.AllowedTransform = AllowedTransform = {}));
