"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionFeeType = exports.PlanId = exports.PlanName = void 0;
var PlanName;
(function (PlanName) {
    PlanName["Essential"] = "Essential";
    PlanName["Startup"] = "Startup";
    PlanName["Pro"] = "Pro";
    PlanName["Business"] = "Business";
    PlanName["Custom"] = "Custom";
    PlanName["PayAsYouGrow"] = "Pay as you grow";
    PlanName["Scale"] = "Scale";
})(PlanName || (exports.PlanName = PlanName = {}));
var PlanId;
(function (PlanId) {
    PlanId["PayAsYouGrow"] = "pay_as_you_grow";
    PlanId["Scale"] = "scale";
})(PlanId || (exports.PlanId = PlanId = {}));
var TransactionFeeType;
(function (TransactionFeeType) {
    TransactionFeeType["Flat"] = "flat";
    TransactionFeeType["Tiered"] = "tiered";
})(TransactionFeeType || (exports.TransactionFeeType = TransactionFeeType = {}));
