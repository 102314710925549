"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleThenType = exports.RuleAssertion = void 0;
var RuleAssertion;
(function (RuleAssertion) {
    RuleAssertion["Is"] = "is";
    RuleAssertion["IsOneOf"] = "isOneOf";
    RuleAssertion["Includes"] = "includes";
    RuleAssertion["DoesNotInclude"] = "doesNotInclude";
    RuleAssertion["IsNot"] = "isNot";
    RuleAssertion["MatchesInState"] = "matchesInState";
    RuleAssertion["DoesNotMatchInState"] = "doesNotMatchInState";
})(RuleAssertion || (exports.RuleAssertion = RuleAssertion = {}));
var RuleThenType;
(function (RuleThenType) {
    RuleThenType["SetAuthorizedAnswers"] = "SET_AUTHORIZED_ANSWERS";
    RuleThenType["SetRestrictedAnswers"] = "SET_RESTRICTED_ANSWERS";
    RuleThenType["DisableQuestion"] = "DISABLE_QUESTION";
    RuleThenType["RestrictAllBut"] = "RESTRICT_ALL_BUT";
    RuleThenType["AddRestriction"] = "ADD_RESTRICTION";
})(RuleThenType || (exports.RuleThenType = RuleThenType = {}));
