"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldInputType = void 0;
var FieldInputType;
(function (FieldInputType) {
    FieldInputType["Text"] = "text";
    FieldInputType["TextArea"] = "textarea";
    FieldInputType["Email"] = "email";
    FieldInputType["Phone"] = "phone";
    FieldInputType["Address"] = "address";
})(FieldInputType || (exports.FieldInputType = FieldInputType = {}));
