"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrintAreaMeasurementUnit = void 0;
var PrintAreaMeasurementUnit;
(function (PrintAreaMeasurementUnit) {
    PrintAreaMeasurementUnit["Inches"] = "inches";
    PrintAreaMeasurementUnit["Centimeters"] = "centimeters";
    PrintAreaMeasurementUnit["Millimeters"] = "millimeters";
    PrintAreaMeasurementUnit["Pixels"] = "pixels";
})(PrintAreaMeasurementUnit || (exports.PrintAreaMeasurementUnit = PrintAreaMeasurementUnit = {}));
