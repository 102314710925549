"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DemoComplexity = exports.ProductVersionOrigin = void 0;
var ProductVersionOrigin;
(function (ProductVersionOrigin) {
    ProductVersionOrigin["ProductCreated"] = "productCreated";
})(ProductVersionOrigin || (exports.ProductVersionOrigin = ProductVersionOrigin = {}));
var DemoComplexity;
(function (DemoComplexity) {
    DemoComplexity["Basic"] = "Basic";
    DemoComplexity["Medium"] = "Medium";
    DemoComplexity["Complex"] = "Complex";
})(DemoComplexity || (exports.DemoComplexity = DemoComplexity = {}));
