"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteStatus = void 0;
var QuoteStatus;
(function (QuoteStatus) {
    QuoteStatus["Accepted"] = "accepted";
    QuoteStatus["Open"] = "open";
    QuoteStatus["Draft"] = "draft";
    QuoteStatus["Canceled"] = "canceled";
})(QuoteStatus || (exports.QuoteStatus = QuoteStatus = {}));
