"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentStatus = exports.OrderStatus = void 0;
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["Pending"] = "pending";
    OrderStatus["Cancelled"] = "cancelled";
    OrderStatus["InProduction"] = "inproduction";
    OrderStatus["Fulfilled"] = "fulfilled";
})(OrderStatus || (exports.OrderStatus = OrderStatus = {}));
var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["Unpaid"] = "unpaid";
    PaymentStatus["Paid"] = "paid";
    PaymentStatus["Authorized"] = "authorized";
    PaymentStatus["Partially_refunded"] = "partially_refunded";
    PaymentStatus["Partially_paid"] = "partially_paid";
    PaymentStatus["Refunded"] = "refunded";
    PaymentStatus["PartiallyPaid"] = "partially_paid";
})(PaymentStatus || (exports.PaymentStatus = PaymentStatus = {}));
