"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuestionInputType = exports.QuestionType = void 0;
var QuestionType;
(function (QuestionType) {
    QuestionType["Material"] = "material";
    QuestionType["Image"] = "image";
    QuestionType["Color"] = "color";
    QuestionType["Text"] = "text";
    QuestionType["Logo"] = "logo";
    QuestionType["Font"] = "font";
    QuestionType["FontSize"] = "fontSize";
    QuestionType["Value"] = "value";
    QuestionType["Outline"] = "outline";
})(QuestionType || (exports.QuestionType = QuestionType = {}));
var QuestionInputType;
(function (QuestionInputType) {
    QuestionInputType["None"] = "none";
    QuestionInputType["Thumbnail"] = "radio";
    QuestionInputType["LargeThumbnail"] = "largeradio";
    QuestionInputType["Text"] = "text";
    QuestionInputType["Number"] = "number";
    QuestionInputType["File"] = "fileinput";
    QuestionInputType["Dropdown"] = "dropdown";
    QuestionInputType["SmallRadio"] = "smallradio";
    QuestionInputType["Label"] = "label";
    QuestionInputType["Checkbox"] = "checkbox";
    QuestionInputType["ColorPicker"] = "colorpicker";
})(QuestionInputType || (exports.QuestionInputType = QuestionInputType = {}));
