import classNames from 'classnames'
import React from 'react'

interface SpinnerProps {
  className?: string
}

const Spinner = ({ className }: SpinnerProps) => (
  <div
    aria-label="loading"
    role="presentation"
    className={classNames('border-2 border-t-transparent animate-spin w-4 h-4 rounded-full', className)}
  />
)

export default Spinner
