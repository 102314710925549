"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrintFilesRequestStatus = exports.DesignType = void 0;
var DesignType;
(function (DesignType) {
    DesignType["CustomizationDesign"] = "customization";
    DesignType["BulkOrderDesign"] = "bulk";
})(DesignType || (exports.DesignType = DesignType = {}));
var PrintFilesRequestStatus;
(function (PrintFilesRequestStatus) {
    PrintFilesRequestStatus["NotInitiated"] = "not-initiated";
    PrintFilesRequestStatus["Pending"] = "pending";
    PrintFilesRequestStatus["Success"] = "success";
    PrintFilesRequestStatus["Error"] = "error";
})(PrintFilesRequestStatus || (exports.PrintFilesRequestStatus = PrintFilesRequestStatus = {}));
