"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandTenantConversionRateRanges = exports.BrandTenantConversionRate = void 0;
var BrandTenantConversionRate;
(function (BrandTenantConversionRate) {
    BrandTenantConversionRate["VeryLow"] = "Very Low";
    BrandTenantConversionRate["Low"] = "Low";
    BrandTenantConversionRate["Medium"] = "Medium";
    BrandTenantConversionRate["High"] = "High";
})(BrandTenantConversionRate || (exports.BrandTenantConversionRate = BrandTenantConversionRate = {}));
exports.BrandTenantConversionRateRanges = {
    [BrandTenantConversionRate.VeryLow]: [0, 0.02],
    [BrandTenantConversionRate.Low]: [0.02, 0.05],
    [BrandTenantConversionRate.Medium]: [0.05, 0.15],
    [BrandTenantConversionRate.High]: [0.15, 1],
};
