"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StripeCardBrand = exports.SubscriptionStatus = exports.CancellationStrategy = exports.PaymentStrategy = exports.StripeItemType = void 0;
var StripeItemType;
(function (StripeItemType) {
    StripeItemType["Addon"] = "addon";
    StripeItemType["TransactionFee"] = "trasaction_fee";
    StripeItemType["RecurringPrice"] = "recurring_price";
})(StripeItemType || (exports.StripeItemType = StripeItemType = {}));
var PaymentStrategy;
(function (PaymentStrategy) {
    PaymentStrategy["Shopify"] = "shopify";
    PaymentStrategy["Wix"] = "wix";
    PaymentStrategy["Manual"] = "manual";
    PaymentStrategy["Stripe"] = "stripe";
    PaymentStrategy["None"] = "none";
})(PaymentStrategy || (exports.PaymentStrategy = PaymentStrategy = {}));
var CancellationStrategy;
(function (CancellationStrategy) {
    CancellationStrategy["Cancel"] = "cancel";
    CancellationStrategy["RequestCancel"] = "requestCancel";
})(CancellationStrategy || (exports.CancellationStrategy = CancellationStrategy = {}));
var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus["Expired"] = "expired";
    SubscriptionStatus["Future"] = "future";
    SubscriptionStatus["FreeTrial"] = "freeTrial";
    SubscriptionStatus["Active"] = "active";
    SubscriptionStatus["RequestCancel"] = "requestCancel";
    SubscriptionStatus["Canceled"] = "canceled";
    SubscriptionStatus["PaymentError"] = "paymentError";
})(SubscriptionStatus || (exports.SubscriptionStatus = SubscriptionStatus = {}));
var StripeCardBrand;
(function (StripeCardBrand) {
    StripeCardBrand["Visa"] = "visa";
    StripeCardBrand["Mastercard"] = "mastercard";
    StripeCardBrand["Amex"] = "amex";
})(StripeCardBrand || (exports.StripeCardBrand = StripeCardBrand = {}));
