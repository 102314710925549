"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityType = void 0;
var EntityType;
(function (EntityType) {
    EntityType["Group"] = "group";
    EntityType["Part"] = "part";
    EntityType["Question"] = "question";
    EntityType["Answer"] = "answer";
    EntityType["PrintArea"] = "printarea";
})(EntityType || (exports.EntityType = EntityType = {}));
