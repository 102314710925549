"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandUserRole = exports.AdminUserRole = void 0;
var AdminUserRole;
(function (AdminUserRole) {
    AdminUserRole["Master"] = "master";
    AdminUserRole["MczrAdmin"] = "mczr-admin";
    AdminUserRole["EcommerceConnector"] = "ecommerce-connector";
    AdminUserRole["JobRunner"] = "job-runner";
})(AdminUserRole || (exports.AdminUserRole = AdminUserRole = {}));
var BrandUserRole;
(function (BrandUserRole) {
    BrandUserRole["Admin"] = "admin";
})(BrandUserRole || (exports.BrandUserRole = BrandUserRole = {}));
