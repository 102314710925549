"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopifyThemeType = exports.CustomizerIntegrationType = exports.LegacyCustomizerIntegrationType = void 0;
var LegacyCustomizerIntegrationType;
(function (LegacyCustomizerIntegrationType) {
    LegacyCustomizerIntegrationType["CustomizeButton"] = "customize-button";
    LegacyCustomizerIntegrationType["CustomizerOnly"] = "customizer-only";
})(LegacyCustomizerIntegrationType || (exports.LegacyCustomizerIntegrationType = LegacyCustomizerIntegrationType = {}));
var CustomizerIntegrationType;
(function (CustomizerIntegrationType) {
    CustomizerIntegrationType["CustomizeButton"] = "customize_button";
    CustomizerIntegrationType["CustomizerOnly"] = "customizer_only";
})(CustomizerIntegrationType || (exports.CustomizerIntegrationType = CustomizerIntegrationType = {}));
var ShopifyThemeType;
(function (ShopifyThemeType) {
    ShopifyThemeType["AppEmbedBlock"] = "app-embed-block";
    ShopifyThemeType["Legacy"] = "legacy";
})(ShopifyThemeType || (exports.ShopifyThemeType = ShopifyThemeType = {}));
