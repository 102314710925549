import classNames from 'classnames'
import React from 'react'

import Popover from './popover/Popover'
import usePopover, { usePopoverProps } from './popover/usePopover'

export interface TooltipProps extends usePopoverProps {
  className?: string
  containerClassName?: string
  popoverContainerClassName?: string
  content?: React.ReactNode
  children?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  disabled?: boolean
  visible?: boolean
  arrow?: boolean
}

const Tooltip = ({
  content,
  children,
  className,
  containerClassName,
  popoverContainerClassName,
  placement = 'top',
  onClick,
  disabled = false,
  visible = false,
  arrow = true,
  ...rest
}: TooltipProps) => {
  const popover = usePopover({
    useArrow: arrow,
    placement,
    forceOpen: visible,
    trigger: 'hover',
    offsetConfig: { mainAxis: 12 },
    restMs: 250,
    ...rest,
  })

  return (
    <>
      <span
        {...popover.referenceProps}
        onClick={onClick}
        className={classNames({ 'hover:cursor-pointer': !disabled }, containerClassName)}
      >
        {children}
      </span>
      {!disabled && (
        <Popover
          className={classNames(className, 'p-2.5 min-w-0 max-w-xs')}
          containerClassName={popoverContainerClassName}
          isOpen={popover.isOpen}
          {...popover.floatingProps}
        >
          <div className="overflow-hidden text-ellipsis whitespace-pre-line">{content}</div>
        </Popover>
      )}
    </>
  )
}

export default Tooltip
